
body {
	background-color: black;
}

.App {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.AppScroll {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.AppScroll::-webkit-scrollbar {
    display: none;
}

.AppMain {
	overflow: hidden;
}

.AppLogoPad {
	padding: 6px;
}

.AppLogoPad .ActionButtonContainer {
	width: 96px !important;
	height: 22px !important;
}

.AppLogo {
    height: 22px;
	background: url(img/title.svg) no-repeat;
	background-size: contain;
	opacity: 0.85;
	cursor: pointer;
}

.AppHeaderButton {
	width: 96px !important;
	height: 22px !important;
	border-radius: 4px !important;
	opacity: 0.85;
	box-shadow: rgb(0 0 0 / 0.05) 0px 0px 10px 4px !important;
}

.AppInputPad {
    background-color: #ecec00;
	text-align: left;
	padding: 6px;
}

.AppInput {
	border: none;
	background-color: transparent;
	width: 100%;
	padding: 0;
}

.AppInput::placeholder {
	color: rgba(0, 0, 0, 0.5);
}

.AppText {
	color: rgba(255, 255, 255, 0.85);
	padding: 10px;
}

.AppText a {
	color: rgb(0, 255, 0);
}

.AppTopControls {
	position: relative;
	padding: 20px;
	background-color: #d7d700;
}

.AppTopControlsCentre {
	position: relative;
	width: 50px;
	margin: 0 auto;
}

.AppTop {
	background-color: #ffff00 !important;
}

.AppTileRows {
	position: relative;
}

.AppTileRow {
	display: flex;
	display: -webkit-flex;
}

.AppInfo {
	color: rgba(255, 255, 255, 0.7);
	padding: 10px;
	text-transform: uppercase;
	font-size: 0.8em;
	font-weight: bold;
	text-align: center;
}

.AppZoom {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.95);
	cursor: pointer;
}

.AppZoomSourceContainer {
	position: absolute;
	left: 10px;
	top: 10px;
}

.AppZoomSource {
	background-color: #ffff00;
	background-size: cover;
}

.AppZoomMoveContainer {
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
}

.AppZoomMoveContainerLeft {
	left: 10px;
}

.AppZoomMoveContainerRight {
	right: 10px;
}

.AppHeaderProfileButton .ActionButtonImage,
.AppHeaderLogoutButton .ActionButtonImage,
.AppHeaderWaitingButton .ActionButtonImage {
	border-radius: 4px !important;
}

.AppZoomMoveLeft {
	background-color: #00000020 !important;
	box-shadow: none !important;
	background-image: url(img/left.svg);
}

.AppZoomMoveRight {
	background-color: #00000020 !important;
	box-shadow: none !important;
	background-image: url(img/right.svg);
}