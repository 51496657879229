
@import url('https://fonts.googleapis.com/css?family=Noto+Sans');

* {
	-webkit-tap-highlight-color: transparent;
}

body {
	margin: 0;
    padding: 0;
}

body, h1, p, input {
    font-family: 'Noto Sans', sans-serif;
	font-size: 1em;
}

input:focus {
	outline: none;
}

h1, p {
	margin: 0.8em 0;
}

h1 {
	font-weight: bold;
}

p {
	opacity: 0.8;
}

div {
	user-select: none;
}
